<template>
  <el-dialog
    v-el-drag-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$t('deviceGateway.editDevice')"
    width="800px"
    top="3vh"
    @close="$reset('form')">
    <el-form
      ref="form"
      label-width="80px"
      :model="model">
      <div v-if="model.id > 0" class="vm-separate">
        <el-form-item :label="$t('deviceGateway.dtuCode')">
          <el-input v-model.trim="model.dtuCode" disabled=""></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.networkFormat')" prop="networkFormat">
          <el-input v-model.trim="model.networkFormat" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>

    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$t("elevatorNetApply.cancel")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$t("elevatorNetApply.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        model: {
          id: 0,
          dtuCode: "",
          networkFormat: "",
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        if (this.model.id) {
          this.getData();
        }
      },
      getData() {
        this.$http
          .get("deviceGatewayTpNVR/" + this.model.id)
          .then(({data}) => {
            this.$assign(this.model, data);
          });
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http.save("deviceGatewayTpNVR", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.saveSuccess"));
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
</style>
